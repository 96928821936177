var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"公司名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code']),expression:"['code']"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"法人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contact']),expression:"['contact']"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone']),expression:"['phone']"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email']),expression:"['email']"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"银行","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('Bank',{attrs:{"list":_vm.bankList},on:{"addBank":_vm.addBank,"setBank":_vm.setBank,"removeBank":_vm.removeBank}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }